<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Select the dataset to analyze"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors }"
            :rules= {required:true}
            name="dataset"
          >
            <dataset-select
              label="Select a dataset"
              :dataset.sync="dataset"
              v-model="dataset"
              :withVarname="false"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              icon="table"
              pack="fa"
              expanded
            />
          </validation-provider>
          <hr />
          <b-field label="Imputation method">
            <b-select
              v-model="method"
              placeholder="Imputation method"
              expanded
            >
              <option value="pca">PCA</option>
              <option value="knn">KNN</option>
              <option value="zero">Replace NA’s by zeros</option>
            </b-select>
          </b-field>
          <hr  v-if="method!=='zero'" />
          <b-field label="Type of variables to impute" v-if="method!=='zero'">
            <b-select
              v-model="typeVar"
              placeholder="Type of variables to impute"
              expanded
            >
              <option value="a">Auto</option>
              <option value="c">Categorical</option>
              <option value="n">Numeric</option>
              <option value="m">Mixed</option>
            </b-select>
          </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Run </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component
      title="2. Plots and numerical summaries"
      />
      <card-no-result v-if="!resultsLoaded">
        <strong>How to set options?<br></strong>
        Imputation method can be chosen according to what you know on the missing
        values and subsequent analyses:
        <ul>
          <li><strong>imputation by zeros</strong> is dedicated to cases where
          missing values are due to a measurements below the detection
          threshold. It is a very basic approach to address this problem but
          certainly not the best;</li>
          <li><strong>imputation by PCA</strong> is well designed when you want
          to use PCA, MFA, or PLS (for instance) afterwards because it best
          preserves the projection of your individuals on PC axes;</li>
          <li><strong>imputation by k-nearest neighbors</strong> is based on
          the idea that two individuals that are similar on observed values also
          have similar values for unobserved variables. It best preserves the
          distances between individuals and is well adapted prior clustering.</li>
        </ul>
        The last two methods are only valid when data are missing at random.<br>
        <br>
        In addition, for PCA and KNN you can choose to impute <strong>only
        certain types of variables</strong> (only numerical or only categorical
        variables). Setting this option to “Auto”  imputes only variables
        of the most present type while “Mixed” imputes both numerical and
        categorical variables.
      </card-no-result>
      <div v-if="resultsLoaded">
        <error :type='"notifications"' ref="notifications"></error>
        <div v-if="hasResults">
          <card-component >
            <b-tabs v-model="currentResult">
              <b-tab-item label="Summary" icon="table" value="summary" />
              <b-tab-item label="Plots" icon="bar-chart" value="plots" />
              <b-tab-item label="Extract" icon="plus" value="extract" />
            </b-tabs>
          </card-component>
          <div v-if="currentResult==='summary'">
            <card-component
              :title="basicTable.title"
            >
              <basic-table :substrRowname="false" :data="basicTable"/>
            </card-component>
          </div>
          <card-component v-if="currentResult==='plots'">
              <div>
                <png v-if="png" :data=png />
              </div>
              <div v-if="graphicals.length>0">
                <plotly :height="800"
                  :data="graphicals[0]"
                ></plotly>
              </div>
          </card-component>
          <card-component v-if="currentResult==='extract'">
            <validation-observer v-slot="{ handleSubmit }">
              <validation-provider
                v-slot="{ errors, valid }"
                :rules= {excluded:datasetsNames,does_not_contains:true,authorize_name:true,not_too_long:true}
                :customMessages = "{excluded: 'This dataset name has already been used. Choose another one.',
                does_not_contains: 'The dataset name should not contain special symbols (except underscore) nor start with numbers.',
                authorize_name: 'This dataset name is not allowed',
                not_too_long: 'This dataset name is too long'}"
                name="Dataset name"
              >
                <b-field
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label="Dataset name"
                >
                  <b-input
                    v-model="userName"
                    expanded
                    icon="tag"
                    placeholder="Dataset name"
                  ></b-input>
                  <p class="control">
                    <b-button rounded
                      class="is-primary button-shadow"
                    @click="handleSubmit(rExtractImputed)"
                      >
                      <span class="icon is-small"><i class="fa fa-plus"></i></span>
                      <span> Extract and create a new dataset </span>
                    </b-button>
                  </p>
                </b-field>
              </validation-provider>
            </validation-observer>
          </card-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useErrorStore } from '@/stores/error'

export default {
  name: 'RMissingImpute',
  props: {
    datasetP: {
      type: String,
      default: null
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      dataset: this.datasetP,
      method: 'pca',
      typeVar: 'a',
      userName: null,
      objectName: null,
      currentResult: 'summary'
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const errorStore = useErrorStore()
    return { workspaceStore, errorStore }
  },
  computed: {
    params: function () {
      return {
        'func_name': 'r_missing_impute',
        'datasetName': this.dataset,
        'method': this.method,
        'out_graph': true,
        'typeVar': this.typeVar
      }
    },
    datasetsNames: function () {
      return this.workspaceStore.datasets.map(dataset => dataset.user_name)
    },
    datasetUserName: function () {
      return this.workspaceStore.datasets.filter(obj => obj.object_name === this.datasetP)[0].user_name
    }
  },
  mounted () {
    extend('does_not_contains', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.match(/^[a-zA-Z0-9_]*$/) && !value.match(/^\d/)
    })
    extend('not_too_long', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.length < 40
    })
    extend('authorize_name', (value) => {
      return apiService.runRFunction({
        'func_name': 'r_forbidden_name',
        'username': value
      }).then((res) => {
        return {
          valid: !res.forbidden
        }
      }, (err) => {
        return {
          valid: false,
          data: { message: 'This dataset name is forbidden.' + err }
        }
      })
    })
    this.setUserName()
  },
  methods: {
    afterRun: function (data) {
      this.objectName = data.ObjectName
      this.currentResult = 'summary'
      this.setUserName()
    },
    rExtractImputed: function () {
      this.workspaceStore.runRFunction({
        'func_name': 'r_extract_imputed',
        'ObjectName': this.objectName,
        'userName': this.userName,
        'out_graph': true
      })
        .then(data => {
          if ('Messages' in data) {
            this.errorStore.setNotifications(data.Messages.data)
          } else {
            this.errorStore.setNotification({
              'type': 'message',
              'text': 'The dataset <strong>' + this.userName + '</strong> has been created.'
            })
            this.currentResult = 'summary'
          }
        })
    },
    setUserName: function () {
      this.userName = this.datasetUserName + '_' + this.objectName
    }
  },
  watch: {
    dataset: function () {
      this.currentResult = 'summary'
    },
    method: function () {
      this.currentResult = 'summary'
    },
    typeVar: function () {
      this.currentResult = 'summary'
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
  ul {
  list-style: inside;
  padding-left: 10px;
  }
</style>
